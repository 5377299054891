import React, { Fragment, useEffect } from 'react';
import {
    Paper, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, TablePagination, TableSortLabel
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableHeight: {
        maxHeight: 640
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableHead: {
        height: '3em',
    }
}));

export const TableGrid = ({ columns, rows, height, footer, keycol, serverPaging, country, size = 'medium', rowsPerPageOption, ...props }) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(rowsPerPageOption ?? 500);
    const [orderBy, setOrderBy] = React.useState(null);
    const [order, setOrder] = React.useState('asc');
    const classes = useStyles();
    const tableHeight = height ?? 640;

    useEffect(() => {
        if (rows) {
            setPage(0); // Reset the page to 0 every time a search is performed
        }
    }, [rows]);

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        if (orderBy === null)
            return array;

        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }



    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    // Create an array of visible columns by filtering out the hidden ones
    columns = columns.filter((column) => !column.hide);

    return (
        <Paper>

            <TableContainer style={{ maxHeight: tableHeight }}>
                <Table stickyHeader aria-label="table-grid" size="small" {...props}>
                    <TableHead>
                        <TableRow className={classes.tableHead}>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.field}
                                    sx={{ minWidth: column.width, width: !column.autoWidth ? column.width : 'auto', fontWeight: 'bold', backgroundColor: '#909090', color: 'white', fontSize: (size === 'small') ? '12px' : '14px' }}

                                >
                                    {column.hideSortIcons ? column.renderHeader?.() || column.headerName :
                                        <TableSortLabel
                                            active={orderBy === column.field}
                                            direction={orderBy === column.field ? order : 'asc'}
                                            onClick={createSortHandler(column.field)}
                                        >
                                            {column.headerName}
                                            {orderBy === column.field ? (
                                                <span className={classes.visuallyHidden}>
                                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    }

                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {serverPaging ?
                            rows?.map((row, index) => {
                                return (<TableRow hover role="checkbox" tabIndex={-1} key={keycol ? row[keycol] : index}>
                                    {columns.map((column) => {
                                        const value = row[column.field];
                                        return (
                                            <TableCell key={column.field} align={column.align} sx={{ wordBreak: 'break-word', fontSize: (size === 'small') ? '12px' : '14px' }}>
                                                {column.renderCell?.({ value: value, id: keycol ? row[keycol] : index, field: column.field, index, row }) || ((column.type === 'boolean') ? (value ? <CheckIcon /> : <ClearIcon />)
                                                    : (column.valueFormatter && typeof value === 'number' ? column.valueFormatter(value) : value))}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>);
                            })
                            :
                            (rows) ? stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {

                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={keycol ? row[keycol] : index}>
                                            {columns.map((column) => {
                                                const value = row[column.field];
                                                return (
                                                    <TableCell key={column.field} align={column.align} sx={{ wordBreak: 'break-word', fontSize: (size === 'small') ? '12px' : '14px' }} >
                                                        {column.renderCell?.({ value: value, id: keycol ? row[keycol] : index, field: column.field, index, row }) || (
                                                            (() => {
                                                                // Check the type and format properties to apply formatting
                                                                if (column.type === 'boolean') {
                                                                    // Boolean type, render checkmark or clear icon
                                                                    return value ? <CheckIcon /> : <ClearIcon />;
                                                                } else {
                                                                    // Handle other data types and formatting
                                                                    if (column.type === 'currency') {
                                                                        // Apply currency formatting
                                                                        return new Intl.NumberFormat('en-US', {
                                                                            style: 'currency',
                                                                            currency: column.format || 'USD',
                                                                        }).format(value);
                                                                    } else if (column.type === 'date') {
                                                                        // Apply date formatting
                                                                        return dayjs(value).format(column.format ?? 'MM/DD/YYYY');
                                                                    } else if (column.valueFormatter && typeof value === 'number') {
                                                                        // Custom value formatter for numbers
                                                                        return column.valueFormatter(value);
                                                                    } else {
                                                                        // Default rendering for other cases
                                                                        return value;
                                                                    }
                                                                }
                                                            })())}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );

                                }) : <Fragment />
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {footer ||
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100, 500, 1000]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />}
        </Paper>
    );
}