import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Container, Typography, Paper, LinearProgress,  Button, Grid, TextField, Checkbox, FormControlLabel, RadioGroup, Radio, CircularProgress, Stack} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ApiPaths, ApiRoot } from '../../../core/PathConstants';
import { fetchApiData, postApiData } from '../../../core/FetchData';
import AlertMessage from '../../../core/AlertMessage';
import SearchIcon from '@mui/icons-material/Search';
import { Fragment } from 'react';
import { TableGrid } from '../../../core/TableGrid';
import { ListboxControl } from '../../../core/useForm';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import Snackbar from '@mui/material/Snackbar';


export const QuarterlyPricing = function ({country}) {
    const [inProcess, setInProcess] = useState(null);
    const [samSelection, setSamSelection] = useState("sam-all");
    const [samSelData, setSamSelData] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [isSamLoading, setIsSamLoading] = useState(true);
    const [notification, setNotification] = useState('');




    const [samClasses, setSamClasses] = useState(null);

    const [includeFixedPrice, setIncludeFixedPrice] = useState(false);

    const [openConfirm, setConfirmOpen] = React.useState(false);


    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('error');
       

    //const handleConfirmClose = () => {
    //    setConfirmOpen(false);
    //};

    const showAlert = (message, type) => {
        setAlertMessage(message);
        if (type) {
            setAlertType(type);
        }
        else if (alertType !== 'error')
            setAlertType('error');

        setOpenAlert(true);
    }


    const onError = (errMsg) => {
        showAlert(errMsg, 'error');
    };

    //const onDelComplete = () => {
    //    showAlert('Vehicle Pricing Authorization deleted', 'success');
    //    setVehicleId(null);
    //    setData(null);
       
    //};

    ////DEL functions
    ////on click yes to delete
    //const handleYes = () => {
    //    setConfirmOpen(false);
    //    if (vehicleId) {
    //        postApiData(`${ApiRoot}/${country}/${ApiPaths.VehicleAuthorization}/${vehicleId}`, 'DELETE', null, onDelComplete, onError);
    //    }
    //};


    //const onDel = (id) => {

    //    setConfirmOpen(true);
    //}

    const onNotification = (d) => {
        console.log(d);
        if (d.message)
            showAlert(d.message, 'info');
       // setNotification(d)
    }

    const onData = (d) => {
        console.log(d);
        setInProcess(d);
    }

    const onSamError = (msg) => {
        onError(msg);
    }
    //sam classes
    const onSamClasses = (samData) => {
     
        setSamClasses(samData);
    }

   
    useEffect(() => {

        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.FilterSamClasses}`, onSamClasses, setIsSamLoading, onSamError);

        fetchApiData(`${ApiRoot}/${country}/${ApiPaths.QuarterlyPricing}`, onData, setIsLoading, onError);

        //fetchApiData(`${ApiRoot}/${country}/${ApiPaths.QuarterlyPricing}/notif`, onNotification, null, null);

        //set every 15 seconds
        const interval = setInterval(() => {
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.QuarterlyPricing}/notif`, onNotification, null, null);
            fetchApiData(`${ApiRoot}/${country}/${ApiPaths.QuarterlyPricing}`, onData, setIsLoading, onError);

        }, 15000);

        return () => clearInterval(interval);
      
    }, []);


    



    const onSuccess = () => {

     }

    const stepClicked = (step) => {


        let samClasses = (samSelection === "sam-all") ? null : samSelData.map((item) => { return item.code; });

        let qpStep = { step, country, includeFixedPrice, samClasses }
        console.log(qpStep);
        setInProcess([{id:0, value: 1}]);

        postApiData(`${ApiRoot}/${country}/${ApiPaths.QuarterlyPricing}`, 'POST', qpStep, onSuccess, onError);



    }
    //const handleClose = () => {
    //    setNotification('');
    //};


    const handleIncludeFixedPrice = (event) => {
        setIncludeFixedPrice(event.target.checked);
    };

    const handleSamSelection = (event) => {
        let val = event.target.value;
        if (val === 'sam-all')
            setSamSelData([]);
        setSamSelection(val); 
    };

    const handleSAMClasses = (sels, value) => {
        //if any is selected then deselect the others
        console.log(sels);
        setSamSelData(sels);
    }

    return (
        <Container>
            <Typography variant="h5" style={{ margin: 3 }}>Execute Quarterly Pricing Job</Typography>
            <Paper sx={{ p: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                       <FormControlLabel
                            label="Include Fixed Price"
                            control={<Checkbox checked={includeFixedPrice} onChange={handleIncludeFixedPrice} />}
                        />
                        <RadioGroup
                            row
                            aria-labelledby="sam-calsses-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={samSelection}
                            onChange={handleSamSelection}
                        >
                            <FormControlLabel value="sam-all" control={<Radio />} label="All SAM Classes" />
                            <FormControlLabel value="sam-ind" control={<Radio />} label="Individual SAM Classes" />  
                        </RadioGroup>
                        {samSelection === 'sam-ind' && ((isSamLoading) ? <CircularProgress /> :
                            <div>
                                {/* <TableGrid rows={samClasses} />*/}
                                <ListboxControl
                                    label="Select SAM Classes"
                                    textValue="name"
                                    options={samClasses}
                                    multiselect
                                    selectedItems={samSelData}
                                    handleChange={handleSAMClasses}
                                    height={600}
                                   
                                />
                            </div>)
                        }
                    </Grid>
                    <Grid item xs={6}>
                        {!isLoading && (!inProcess || inProcess?.length === 0) ?
                            <Fragment>
                                <Typography variant="subtitle1" >When the step is successfully executed, email will be sent to uvs_pricing@ryder.com</Typography>
                                <Stack spacing={2} mt={4} >
                                    <Button onClick={() => stepClicked(1)} color="primary" variant="contained" >Copy From Perm to Temp (Step 2)</Button>
                                    <Button onClick={() => stepClicked(2)} color="primary" variant="contained" >Price Temp (Step 3)</Button>
                                    <Button onClick={() => stepClicked(3)} color="primary" variant="contained" >Copy Temp to Perm (Step 7)</Button>
                                    <Button onClick={() => stepClicked(4)} color="primary" variant="contained" >Set User Id to 0 (Step 10)</Button>
                                </Stack>
                            </Fragment>
                            :
                            <Fragment>
                                <Typography variant="subtitle1" >Job is still in process...</Typography>
                                <LinearProgress />
                            </Fragment>}
                    </Grid>
                    
                </Grid>
            </Paper>
           
            <AlertMessage openAlert={openAlert} alertMessage={alertMessage} alertType={alertType} setOpenAlert={setOpenAlert} />

        </Container>
    );

}
