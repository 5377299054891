import axios from 'axios';
import { ApiRoot } from './PathConstants';
import { bearerToken } from './FetchData';

const getEnvKey = () => {
    const envKeys = {
        'https://apidev.': '764acbb677c44f8cb29e0b173d997541',
        'https://apiqa.': '34c541467c284dbc8fc3c4fe3378841f',
        'https://api.': '27b18df440644a778ec376c13241bc08'
    };

    const foundKey = Object.keys(envKeys).find(key => ApiRoot.startsWith(key));
    return envKeys[foundKey] || '764acbb677c44f8cb29e0b173d997541';
};

// Set defaults headers
axios.defaults.baseURL = `${ApiRoot}/`;
axios.defaults.cache = "no-store";
axios.defaults.headers.common = {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': getEnvKey()
};

axios.interceptors.request.use(config => {
    config.headers['Authorization'] = `Bearer ${bearerToken.token}`;
    return config;
});

axios.interceptors.response.use(undefined, (error) => {
    if (error.message === "Network Error" && !error.response) {
        console.error("Network error - Make sure API is running!");
        throw error;
    }

    if (!error.response)
        throw error;

    const { status, data } = error.response;

    if (status === 401) {
        console.error("Session expired!");
    }

    if (data.hasOwnProperty("description")) {
        console.error(`Error: ${status}: ${data.description}`);
    }

    if (status === 500) {
        console.error("Server error - check the terminal for more info!");
    }

    throw error.response;
});

const responseBody = ({ data, headers }) => ({ data, headers });

export const requests = {
    get: (url, config) => axios.get(url, config).then(responseBody),
    post: (url, body, config) => axios.post(url, body, config).then(responseBody),
    put: (url, body, config) => axios.put(url, body, config).then(responseBody),
    delete: (url) => axios.delete(url).then(responseBody),
};
