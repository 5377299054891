import { TableGrid } from '../../../../core/TableGrid';
import { Button, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useNewPromoContext } from './NewPromoContextProvider.js';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js';
import { ApplicationPaths } from '../../../../core/PathConstants.js';
import { PromotionStatuses } from './newPromoConstants.js';

const NewPromoList = ({ data, country, ...props }) => {

  const columns = [
    {
      field: 'promotionId',
      hide: true
    },
    {
      field: 'promotionKey',
      headerName: 'Name',
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: 'dates',
      headerName: 'Dates/Times',
      width: 100,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const { startDateTime, endDateTime } = row;
        return (
          <Grid container spacing={2}>
            <Grid item>
              <Typography variant="inherit">
                {dayjs(startDateTime).format('MM/DD/YYYY')}
              </Typography>
              <Typography variant="inherit">
                {dayjs(startDateTime).format('hh:mm A')}
              </Typography>
            </Grid>
            <Grid item>-</Grid>
            <Grid item>
              <Typography variant="inherit">
                {dayjs(endDateTime).format('MM/DD/YYYY')}
              </Typography>
              <Typography variant="inherit">
                {dayjs(endDateTime).format('hh:mm A')}
              </Typography>
            </Grid>
          </Grid>
        );
      }
    },
    {
      field: 'actions',
      headerName: '',
      width: 100,
      disableColumnMenu: true,
      hideSortIcons: true,
      disableClickEventBubbling: true,
      renderCell: ({ row }) => {
        const isSavedPromo = row.status === PromotionStatuses.Saved;
        return (
          <div style={{ textAlign: 'right' }}>
            <Button aria-label='Edit'
              onClick={(event) => {
                event.ignore = true;
                onEdit(row.promotionId);
              }
              } >
              Edit
            </Button>
            <Button aria-label='Discontinue/Delete'
              onClick={(event) => {
                event.ignore = true;
                console.log(`Delete: ${row.promotionId}`);
                // onEdit(params.value);
              }
              } >
              {isSavedPromo ? 'Delete' : 'Discontinue'}
            </Button>
            {isSavedPromo && (
              <Button aria-label='Publish'
                onClick={(event) => {
                  event.ignore = true;
                  console.log(`Publish: ${row.promotionId}`);
                  // onEdit(params.value);
                }
                } >
                Publish
              </Button>
            )}

          </div>
        );
      }
    },
  ];

  const history = useHistory();
  const { setPromotion } = useNewPromoContext();

  const onEdit = (promotionId) => {
    setPromotion(prevState => ({ ...prevState, promotionId }));
    history.push(`/${country}${ApplicationPaths.CreateNewPromo}`);
  }

  return (
    <div {...props}>
      <TableGrid rows={data} columns={columns} keycol='promotionId' height={580} rowsPerPageOption={10} />
    </div>
  );
}

export default NewPromoList;

