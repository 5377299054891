import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNewPromoContext } from './NewPromoContextProvider';
import { Body, Footer, Header, PromoLayout } from './PromoLayout';
import { Checkbox, Grid, MenuItem, MenuList, Paper, Typography } from '@mui/material';
import { ApiPaths } from '../../../../core/PathConstants';
import { requests } from '../../../../core/ApiService';
import ConfirmationPopup from '../../../../core/ConfirmPopup';

function SetPricingModal({ country, open, onClose }) {
    const [agingBuckets, setAgingBuckets] = useState([]);
    const [error, setError] = useState('');
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [selectedAgingBucket, setSelectedAgingBucket] = useState(-1);
    const { setLoading, showAlert, promotion, setPromotion, staticData } = useNewPromoContext();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                const { data } = await requests.get(`${country}/${ApiPaths.NewPromo}/${promotion.promotionId}/aging-buckets`);
                setAgingBuckets(data);
            } catch (err) {
                showAlert(`Process failed while retrieving aging buckets for promotion ${promotion.promotionKey}!`, 'error');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [country, promotion.promotionId, promotion.promotionKey, setLoading, showAlert]);

    const handleSave = async (saveForLater) => {
        setLoading(true);

        try {
            // await requests.post(`${country}/${ApiPaths.NewPromo}/${promotion.promotionId}/agingBuckets`, { excludedVehicles, exclusionTypeIds: exclusionTypes.map(et => (et.exclusionTypeId)) });
            // showAlert('Vehicles for exclusion added successfully!', 'success');

            // Update the promotion object based on the changed agingBuckets
            // const updatedPromotion = {
            //     ...promotion,
            //     excludedVehicles,
            //     exclusionTypes
            // };

            // setPromotion(updatedPromotion);
            saveForLater ? onClose('cancel') : onClose();
        } catch ({ data }) {
            showAlert(data?.description || 'Process failed while creating Promotion!', 'error');
        }
        finally {
            setLoading(false);
        }
    };

    const handleCancel = () => {
        setConfirmOpen(true);
    };

    const handleConfirm = (isCancel) => {
        setConfirmOpen(false);
        if (isCancel) {
            onClose('cancel');
        }
    };

    const handleMenuItemClick = (ab) => {
        setSelectedAgingBucket(ab.standardAgingBucketId);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
            <PromoLayout>
                <Header>
                    <DialogTitle style={{ textAlign: 'center' }}>Set Pricing Parameters for <b>{promotion.promotionKey}</b></DialogTitle>
                </Header>
                <Body>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12} md={10}>

                            </Grid>
                            <Grid item xs={12} md={2}>
                                {staticData.agingBuckets?.length > 0 &&
                                    <Paper elevation={2} style={{ backgroundColor: '#c8e6c9' }}>
                                        <Typography variant='h6' style={{ alignSelf: 'flex-start', paddingLeft: '16px' }}>Aging Buckets</Typography>
                                        <MenuList>
                                            {staticData.agingBuckets.map((ab) => (
                                                <MenuItem
                                                    key={ab.standardAgingBucketId}
                                                    onClick={() => handleMenuItemClick(ab)}
                                                    style={{ paddingTop: '4px', paddingBottom: '4px' }}
                                                >
                                                    <Checkbox
                                                        checked={ab.standardAgingBucketId === selectedAgingBucket}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        style={{ padding: '0', marginRight: '8px' }}
                                                    />
                                                    <Typography variant="body2" style={{ margin: '0' }}>
                                                        {ab.minDays} - {ab.maxDays}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </Paper>
                                }
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogContent>
                    </DialogContent>
                    <ConfirmationPopup
                        open={confirmOpen}
                        onClose={() => handleConfirm()}
                        onConfirm={() => handleConfirm(true)}
                        message="Are you sure you want to cancel all modifications? Data will not be saved!"
                    />
                </Body>
                <Footer>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Button variant='contained' color='inherit' style={{ marginRight: '16px' }} onClick={onClose}>Back</Button>
                            <Button variant='contained' color='inherit' onClick={handleCancel}>Cancel</Button>
                        </Grid>
                        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant='contained' color='inherit' style={{ marginRight: '16px' }} onClick={() => handleSave(true)} disabled={!!error}>Save for later</Button>
                            <Button variant='contained' color='inherit' onClick={() => handleSave()} disabled={!!error}>Save and Continue</Button>
                        </Grid>
                    </Grid>
                </Footer>
            </PromoLayout>
        </Dialog>
    );
}

export default SetPricingModal;
