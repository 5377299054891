import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useNewPromoContext } from './NewPromoContextProvider';
import { Body, Footer, Header, PromoLayout } from './PromoLayout';
import { Box, Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';
import { ApiPaths } from '../../../../core/PathConstants';
import { requests } from '../../../../core/ApiService';
import ConfirmationPopup from '../../../../core/ConfirmPopup';

function ChooseExclusionModal({ country, open, onClose }) {
    const [vehicleNumbers, setVehicleNumbers] = useState("");
    const [exclusions, setExclusions] = useState([]);
    const [error, setError] = useState('');
    const [confirmOpen, setConfirmOpen] = useState(false);
    const { setLoading, showAlert, promotion, setPromotion, staticData } = useNewPromoContext();

    const excludedVehicles = exclusions.some(exclusion => exclusion.exclusionTypeId === 8 && exclusion.isChecked);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                const initializedExclusions = staticData.exclusionTypes?.map(exclusion => ({
                    ...exclusion,
                    isChecked: promotion.exclusionTypes?.some(type => type.exclusionTypeId === exclusion.exclusionTypeId) || false,
                }));

                setExclusions(initializedExclusions);

                // If there are excluded vehicles in Promotion, format them and show them
                if (promotion.excludedVehicles?.length > 0) {
                    const numbers = promotion.excludedVehicles.map(v => v).join(' ');
                    setVehicleNumbers(numbers);
                }
            } catch (err) {
                showAlert('Process failed while retrieving exclusion types!', 'error');
                setExclusions([]);
                setVehicleNumbers("");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [promotion.excludedVehicles, promotion.exclusionTypes, setLoading, showAlert, staticData.exclusionTypes]);


    const handleCbChange = (event, exclusionTypeId) => {
        const updatedExclusions = exclusions.map(exclusion => {
            if (exclusion.exclusionTypeId === exclusionTypeId) {
                return { ...exclusion, isChecked: event.target.checked };
            }
            return exclusion;
        });

        setExclusions(updatedExclusions);

        // If List Individual Vehicles is unchecked, clean vehicleNumbers
        if (exclusionTypeId === 8 && !event.target.checked)
            setVehicleNumbers("");
    };


    const handleChange = (event) => {
        const input = event.target.value;

        // Check if the input only contains numbers or allowed delimiters (comma, semi-colon or space)
        if (/^[\d,;\s]*$/.test(input)) {
            // Split the input into individual entries based on delimiters (comma, semi-colon or space)
            const entries = input.split(/[,;\s]+/).filter(Boolean);

            // Check if any entry is not exactly 6 characters
            const invalidEntries = entries.some(entry => entry.length !== 6);

            if (invalidEntries) {
                // Set error message if there are entries not exactly 6 characters
                setError("All vehicle numbers must be exactly 6 characters!");
            } else {
                // Clear the error if all entries are valid
                setError('');
            }

            // If the last character is a delimiter, keep the raw input
            if (/[,;\s]$/.test(input)) {
                setVehicleNumbers(input);
            } else {
                // Otherwise, format the input by joining with spaces
                const formattedInput = entries.join(' ');
                setVehicleNumbers(formattedInput);
            }
        }
    };

    const handleSave = async (saveForLater) => {
        setLoading(true);
        const excludedVehicles = vehicleNumbers.split(/[,;\s]+/).filter(Boolean).map(v => +v);
        const exclusionTypes = exclusions.filter(exclusion => exclusion.isChecked);

        try {
            await requests.post(`${country}/${ApiPaths.NewPromo}/${promotion.promotionId}/exclusions`, { excludedVehicles, exclusionTypeIds: exclusionTypes.map(et => (et.exclusionTypeId)) });
            setLoading(false);
            showAlert('Vehicles for exclusion added successfully!', 'success');

            // Update the promotion object based on the changed exclusions
            const updatedPromotion = {
                ...promotion,
                excludedVehicles,
                exclusionTypes
            };

            setPromotion(updatedPromotion);
            saveForLater ? onClose('cancel') : onClose();
        } catch ({ data }) {
            setLoading(false);
            showAlert(data?.description || 'Process failed while creating Promotion!', 'error');
        }
    };

    const handleCancel = () => {
        setConfirmOpen(true);
    };

    const handleConfirm = (isCancel) => {
        setConfirmOpen(false);
        if (isCancel) {
            onClose('cancel');
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <PromoLayout>
                <Header>
                    <DialogTitle style={{ textAlign: 'center' }}>Choose Exclusions for <b>{promotion.promotionKey}</b></DialogTitle>
                </Header>
                <Body>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormGroup>
                                    {exclusions.slice(0, Math.ceil(exclusions.length / 2)).map(exclusion => (
                                        <FormControlLabel
                                            key={exclusion.exclusionTypeId}
                                            control={
                                                <Checkbox
                                                    checked={exclusion.isChecked}
                                                    onChange={(event) => handleCbChange(event, exclusion.exclusionTypeId)}
                                                    name={`exclusion-${exclusion.exclusionTypeId}`}
                                                />
                                            }
                                            label={exclusion.typeDescription}
                                        />
                                    ))}
                                </FormGroup>
                            </Grid>
                            <Grid item xs={6}>
                                <FormGroup>
                                    {exclusions.slice(Math.ceil(exclusions.length / 2)).map(exclusion => (
                                        <FormControlLabel
                                            key={exclusion.exclusionTypeId}
                                            control={
                                                <Checkbox
                                                    checked={exclusion.isChecked}
                                                    onChange={(event) => handleCbChange(event, exclusion.exclusionTypeId)}
                                                    name={`exclusion-${exclusion.exclusionTypeId}`}
                                                />
                                            }
                                            label={exclusion.typeDescription}
                                        />
                                    ))}
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    {excludedVehicles && (
                        <DialogContent>
                            <DialogTitle style={{ textAlign: 'center' }}>Vehicles to Exclude</DialogTitle>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="vehicleNumbers"
                                label="Vehicle Numbers to Exclude"
                                type="text"
                                fullWidth
                                variant="outlined"
                                multiline
                                rows={10}
                                value={vehicleNumbers}
                                onChange={handleChange}
                                placeholder="Enter vehicle numbers separated by commas, semi-colons, or spaces"
                            />
                            {error && <Box color="red">{error}</Box>}
                        </DialogContent>
                    )}
                    <ConfirmationPopup
                        open={confirmOpen}
                        onClose={() => handleConfirm()}
                        onConfirm={() => handleConfirm(true)}
                        message="Are you sure you want to clear all exclusions? Data will not be saved!"
                    />
                </Body>
                <Footer>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Button variant='contained' color='inherit' style={{ marginRight: '16px' }} onClick={onClose}>Back</Button>
                            <Button variant='contained' color='inherit' onClick={handleCancel}>Cancel</Button>
                        </Grid>
                        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant='contained' color='inherit' style={{ marginRight: '16px' }} onClick={() => handleSave(true)} disabled={!!error}>Save for later</Button>
                            <Button variant='contained' color='inherit' onClick={() => handleSave()} disabled={!!error}>Save and Continue</Button>
                        </Grid>
                    </Grid>
                </Footer>
            </PromoLayout>
        </Dialog>
    );
}

export default ChooseExclusionModal;
