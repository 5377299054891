import React, { useCallback, useEffect, useState } from 'react';
import { Typography, Paper, Grid, Button, MenuList, MenuItem, ListItemIcon, List, ListItem } from '@mui/material';
import { Body, Footer, Header, PromoLayout } from './PromoLayout.js';
import { useHistory } from 'react-router-dom';
import { ApiPaths, ApplicationPaths } from '../../../../core/PathConstants.js';
import { useNewPromoContext } from './NewPromoContextProvider.js';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.js';
import { requests } from '../../../../core/ApiService.js';
import CheckIcon from '@mui/icons-material/Check';
import ChooseExclusionModal from './ChooseExclusionModal.js';
import SetPricingModal from './SetPricingModal.js';

export const SetUpPromoHome = ({ country }) => {
    const [exclusionModalOpen, setExclusionModalOpen] = useState(false);
    const [pricingModalOpen, setPricingModalOpen] = useState(false);
    const { setLoading, showAlert, promotion, staticData, setStaticData } = useNewPromoContext();

    const history = useHistory();
    const location = useLocation();

    const goToPreviousPage = () => history.goBack();

    const goToPromoHomePage = useCallback(() => {
        history.replace(`/${country}${ApplicationPaths.NewPromo}`);
    }, [country, history]);

    useEffect(() => {
        // Check if the referrer route matches the allowed route
        const allowedReferrer = `/${country}${ApplicationPaths.CreateNewPromo}`;
        const previousRoute = location.state?.from;

        // Redirect to New Promo Home Page
        if (previousRoute !== allowedReferrer || !promotion?.promotionId) {
            goToPromoHomePage();
        }

        const getAgingBuckets = async () => {
            try {
                setLoading(true);

                const { data } = await requests.get(`${country}/${ApiPaths.NewPromo}/static-data`);
                setStaticData(data);
            } catch ({ data }) {
                setStaticData([]);
                showAlert(data?.description || "Failed retrieving Static Data!", 'error');
            }
            finally {
                setLoading(false);
            }
        }

        // Retrieve all Static Aging Buckets
        getAgingBuckets();
    }, [country, goToPromoHomePage, location.state?.from, promotion?.promotionId, setLoading, setStaticData, showAlert]);

    const handleCloseModal = (action, reason) => {
        // Don't close if clicked outside of the modal
        if (reason === 'backdropClick') {
            return;
        }

        setExclusionModalOpen(false);
        setPricingModalOpen(false);

        if (action === 'cancel') {
            goToPromoHomePage();
        }
    };

    return (
        <>
            <Typography variant='h4' style={{ margin: 3, textAlign: 'center' }}>Set Up <b>{promotion.promotionKey}</b></Typography>
            <PromoLayout>
                <Header>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <Button variant='contained' color='inherit'>Aging Buckets</Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button variant='contained' color='inherit' onClick={() => setExclusionModalOpen(true)}>Choose Exclusions</Button>
                            {exclusionModalOpen && <ChooseExclusionModal country={country} open={exclusionModalOpen} onClose={handleCloseModal} />}
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Button variant='contained' color='inherit' onClick={() => setPricingModalOpen(true)}>Set Pricing</Button>
                            {pricingModalOpen && <SetPricingModal country={country} open={pricingModalOpen} onClose={handleCloseModal} />}
                        </Grid>
                    </Grid>
                </Header>
                <Body>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            {staticData.agingBuckets?.length > 0 &&
                                <Paper elevation={1} sx={{ marginRight: '2.5rem' }}>
                                    <Typography variant='h6' style={{ margin: '.5rem' }}>Manually Set Aging Buckets</Typography>
                                    <MenuList>
                                        {staticData.agingBuckets?.map((ab, index) =>
                                            <MenuItem key={index + 1}>
                                                <Grid container>
                                                    <Grid item xs={1}>{index + 1}.</Grid>
                                                    <Grid item xs={11}>{ab.minDays} - {ab.maxDays} days</Grid>
                                                </Grid>
                                            </MenuItem>
                                        )}
                                    </MenuList>
                                </Paper>
                            }
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {promotion.exclusionTypes?.length > 0 &&
                                <Paper elevation={1} sx={{ marginRight: '2.5rem' }}>
                                    {promotion.exclusionTypes.map((et) =>
                                        <List dense={true} key={et.exclusionTypeId}>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <CheckIcon />
                                                </ListItemIcon>
                                                {et.typeDescription}
                                            </ListItem>
                                        </List>
                                    )}
                                </Paper>
                            }
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {/* Set Pricing */}
                        </Grid>
                    </Grid>
                </Body>
                <Footer>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Button variant='contained' color='inherit' onClick={goToPreviousPage}>Back</Button>
                        </Grid>
                        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant='contained' color='inherit' style={{ marginRight: '16px' }} onClick={goToPromoHomePage}>Save for later</Button>
                            <Button variant='contained' color='primary' disabled>Add Vehicles Now</Button>
                        </Grid>
                    </Grid>
                </Footer>
            </PromoLayout>
        </>
    );
}
