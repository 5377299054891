import React, { useEffect, useState } from 'react';
import { Typography, Paper, Grid, Button } from '@mui/material';
import NewPromoList from './NewPromoList.js';
import { Body, Footer, Header, PromoLayout } from './PromoLayout.js';
import { useHistory } from 'react-router-dom';
import { ApiPaths, ApplicationPaths } from '../../../../core/PathConstants.js';
import { PromotionStatuses } from './newPromoConstants.js';
import { useNewPromoContext } from './NewPromoContextProvider.js';
import { requests } from '../../../../core/ApiService.js';

export const NewPromoHome = ({ country }) => {
    const [promotions, setPromotions] = useState([]);
    const { setLoading, showAlert, resetPromotion } = useNewPromoContext();

    const history = useHistory();

    const handleCreateNewPromo = () => {
        resetPromotion(); // Reset Promotion on New Promotion
        history.push(`/${country}${ApplicationPaths.CreateNewPromo}`);
    }

    useEffect(() => {
        const getPromotions = async () => {
            setLoading(true);
            try {
                const { data } = await requests.get(`${country}/${ApiPaths.NewPromo}`);
                setPromotions(data);
            } catch ({ data }) {
                setPromotions([]);
                showAlert(data?.description || "Failed retrieving Promotions!");
            } finally {
                setLoading(false);
            }
        };

        getPromotions();
    }, [country, setLoading, showAlert]);

    return (
        <>
            <Typography variant='h4' style={{ margin: 3, textAlign: 'center' }}>Promotions Tool ({country.startsWith('ca') ? 'Canada' : 'US'})</Typography>
            <PromoLayout>
                <Header>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant='contained' color='primary' onClick={handleCreateNewPromo}>
                            Create New promotion
                        </Button>
                    </div>
                </Header>
                <Body>
                    <Paper elevation={3} style={{ marginBottom: '1.5rem' }}>
                        <Typography variant='h6' style={{ margin: '.5rem' }}>Active / Published Promotions</Typography>
                        <NewPromoList style={{ padding: 16 }} data={promotions.filter(p => p.status === PromotionStatuses.Active)} country={country}/>
                    </Paper>

                    <Paper elevation={3}>
                        <Typography variant='h6' style={{ margin: '.5rem' }}>Saved Promotions (Not Published)</Typography>
                        <NewPromoList style={{ padding: 16 }} data={promotions.filter(p => p.status === PromotionStatuses.Saved)} country={country}/>
                    </Paper>
                </Body>
                <Footer>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', paddingRight: '8px' }}>
                            <Button variant='contained' color='inherit'>Edit Standard Aging Buckets</Button>
                        </Grid>
                        <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center', paddingLeft: '8px' }}>
                            <Button variant='contained' color='inherit'>Edit Standard Exclusions</Button>
                        </Grid>
                    </Grid>

                </Footer>
            </PromoLayout>
        </>
    );
}
